<template>
  <Modal v-model="logisticsInfoModal" :title="logisticsInfoTitle" width="550" :transfer="false" class-name="commonModalStyle"
    :mask-closable="false" @on-visible-change="logisticsInfoModalChange">
    <Form ref="logisticsInfoForm" :model="logisticsInfoForm" :rules="ruleValidate" :label-width="90" @submit.native.prevent>
      <Row style="width:90%">
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item class="radio_styles" :label="$t('key1001130')" prop="deliveryType">
            <RadioGroup v-model="logisticsInfoForm.deliveryType" @on-change="deliveryTypeChange">
              <Radio :disabled="optionsType === 'billLadingManage'" :label="1">{{ $t('key1001136') }}</Radio>
              <Radio :disabled="optionsType === 'billLadingManage'" :label="2">{{ $t('key1001137') }}</Radio>
            </RadioGroup>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-show="logisticsInfoForm.deliveryType === 1">
          <Form-item class="radio_styles" :label="$t('key1001131')" prop="transportType">
            <RadioGroup v-model="logisticsInfoForm.transportType" @on-change="transportTypeChange">
              <Radio :label="1">{{ $t('key1001138') }}</Radio>
              <Radio :label="2">{{ $t('key1001139') }}</Radio>
            </RadioGroup>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol"
          v-show="logisticsInfoForm.deliveryType === 1 && logisticsInfoForm.transportType === 1">
          <Form-item :label="$t('key1001132')" prop="ymsLogisServiceId">
            <Select v-model="logisticsInfoForm.ymsLogisServiceId" filterable transfer>
              <Option v-for="(item, index) in logisticsPlanList" :value="item.ymsLogisServiceId" :key="index">{{ item.name }}</Option>
            </Select>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-show="logisticsInfoForm.deliveryType === 1">
          <Form-item :label="$t('key1001133')" prop="shippingAddress">
            <Select v-model="logisticsInfoForm.shippingAddress" filterable transfer>
              <Option v-for="(item, index) in shippingAddressList" :value="item.addressId" :key="index">{{ item.shippingAddressName }}</Option>
            </Select>
          </Form-item>
          <div class="flex align-items ml90 font-size-13" v-if="shippingAddressList.length <=0">
            {{ $t('key1001140') }}
            <span class="cursor themeColor" @click="toBasicInformation">{{ $t('key1001141') }}</span>
            {{ $t('key1001142') }}
          </div>
          <div class="ml90 font-size-13" style="color: #E8250B;" v-if="shippingAddressList.length >0 && logisticsInfoForm.transportType === 2">
            {{ $t('key1001143') }}
          </div>
          <div class="ml90 font-size-13" style="color: #E8250B;" v-if="optionsType === 'markShipment'">
            {{ $t('key1001144') }}
          </div>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-show="logisticsInfoForm.deliveryType === 2">
          <Form-item :label="$t('key1001134')" prop="expressageCode">
            <Select v-model="logisticsInfoForm.expressageCode" filterable transfer>
              <Option v-for="(item, index) in typeShippingList" :value="item.code" :key="index">{{ item.name }}</Option>
            </Select>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-show="logisticsInfoForm.deliveryType === 2">
          <Form-item :label="$t('key1001135')" prop="expressageNo">
            <Input clearable v-model.trim="logisticsInfoForm.expressageNo"></Input>
          </Form-item>
        </Col>
      </Row>
    </Form>
    <template #footer>
      <div style="text-align: right;">
        <Button @click="logisticsInfoModal = false">{{ $t('key1000097') }}</Button>
        <Button type="primary" :disabled="shippingAddressList.length <=0 && logisticsInfoForm.deliveryType === 1"
          @click="endPackingBtn" v-if="['endPacking', 'boxManagement'].includes(optionsType)">{{ $t('key1001145') }}</Button>
        <Button type="primary" v-if="btnText" @click="endPackingBtn">{{ btnText }}</Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from "@/components/mixin/common_mixin";

export default {
  name: "logisticsInfoModal",
  mixins: [Mixin],
  data() {
    return {
      logisticsInfoModal: false,
      logisticsInfoForm: {
        deliveryType: 1, // 发货方式 1YMS物流  2自有物流
        transportType: 1, // 运输方式  1快递  2上门揽收
        ymsLogisServiceId: null, // 物流方案
        shippingAddress: null, // 发货地址
        expressageCode: null, //物流商
        expressageNo: '', // 物流单号
      },
      logisticsPlanList: [],
      shippingAddressList: [],
      ruleValidate: {
        ymsLogisServiceId: [
          {required: true, message: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001147'), trigger: 'change'}
        ],
        shippingAddress: [
          {required: true, message: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001148'), trigger: 'change'}
        ],
        expressageCode: [
          {required: true, message: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001149'), trigger: 'change'}
        ],
        expressageNo: [
          {required: true, message: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001150'), trigger: 'blur'}
        ]
      },
      typeShippingList: [],
      wmsPickupOrderId: null,
      logisticsInfoTitle: '',
      optionsType: null,
      pageParamsObj: null,
      btnText: '',
    }
  },
  computed: {
    countrysList() {
      let list = [];
      if (this.countrysData.length > 0) {
        list = this.countrysData.filter((ele) => {
          return ele.twoCode === 'CN'
        });
      }
      return list;
    }
  },
  methods: {
    // 跳转到基本信息
    toBasicInformation() {
      const {href} = this.$router.resolve({
        path: '/accountManage/basicInformation'
      });
      window.open(href, '_blank');
    },
    // 初始化接口数据
    initLogisticsInfo(wmsPickupOrderId, type, pageParamsObj) {
      (async () => {
        if (this.system === 'supplier') {
          await this.getCountrysData();
          this.getLogisticsPlanData();
          this.getAddressData();
        }
        this.getExpressageHundredList();
      })();
      this.wmsPickupOrderId = wmsPickupOrderId;
      this.pageParamsObj = pageParamsObj;
      /**
       * @Description: 操作类型 type
       * endPacking  供应商系统--提货单-->扫描装箱-->结束装箱
       * markShipment  供应商系统--订单列表-->批量发货--> 标记发货
       * shipmentRecord 供应商系统--发货记录-->修改物流信息
       * boxManagement 供应商系统--提货单-->箱/袋管理-->修改物流信息
       * billLadingManage  运营系统--提单管理-->修改物流信息
       *
       */
      this.btnText = '';
      switch (type) {
        case 'endPacking':
        case 'boxManagement':
          this.logisticsInfoTitle = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001151');
          break;
        case 'markShipment':
          this.btnText = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000869');
          this.logisticsInfoTitle = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001152');
          break;
        case 'shipmentRecord':
          this.logisticsInfoTitle = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001153');
          this.btnText = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000374');
          break;
        case 'billLadingManage':
          this.btnText = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000374');
          this.logisticsInfoForm.deliveryType = 2;
          this.logisticsInfoTitle = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001153');
          break;
      }
      this.optionsType = type;
      this.logisticsInfoModal = true;
    },
    // 获取物流方案的基础数据
    getLogisticsPlanData() {
      let v = this;
      let params = {
        name: '',
        carrierId: null,
        shippingMethodId: null,
        merchantType: 3, /* 1 混合物流 2 分销商 3 供应商 */
        status: 1,
        pageNum: 1,
        pageSize: 1000
      };
      v.axios.post(api.post_ymsLogisService_api_query, params).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            v.logisticsPlanList = data.list || [];
            if (v.logisticsPlanList.length > 0 && v.logisticsPlanList.length < 2) {
              v.logisticsInfoForm.ymsLogisServiceId = v.logisticsPlanList[0].ymsLogisServiceId;
            }
          }
        }
      });
    },
    // 获取地址信息数据
    getAddressData() {
      let v = this;
      v.axios.get(api.get_address_query + `?type=pickup`).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data && data.length > 0) {
            data.map((item) => {
              item.countryCodeName = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001154');
              item.stateName = '';
              item.cityName = '';
              item.countryId = v.countrysList[0].countryId
            });
            v.handleAddressData(data);
          }
        }
      });
    },
    // 处理地址信息数据
    handleAddressData(data) {
      let v = this;
      v.shippingAddressList = data;
      let countryId = v.countrysList[0].countryId || null;
      if (countryId) {
        v.getStateData(countryId).then((stateList) => {
          if (stateList.length > 0) {
            v.shippingAddressList.map((item) => {
              item.stateList = stateList;
            });
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              setInterval(fun(item, i, data), 1500);
            }
          }
        });
      }

      function fun(obj, index, data) {
        if (obj.stateList.length > 0) {
          obj.stateList.map((item) => {
            if (item.code === obj.state) {
              v.$set(v.shippingAddressList[index], 'stateName', item.cnName);
            }
          });
        }
        v.getCityData(obj.state).then((cityList) => {
          if (cityList.length > 0) {
            cityList.map((item) => {
              if (item.code === obj.city) {
                v.$set(v.shippingAddressList[index], 'cityName', item.cnName);
              }
            });
            v.$set(v.shippingAddressList[index], 'cityList', cityList);
          }
        });
        v.getDistrictData(obj.city).then((districtList) => {
          if (districtList.length > 0) {
            districtList.map((item) => {
              if (item.code === obj.district) {
                v.$set(v.shippingAddressList[index], 'districtName', item.cnName);
              }
            });
            v.$set(v.shippingAddressList[index], 'districtList', districtList);
          }
        });
        if (index === data.length - 1) {
          setTimeout(() => {
            let cityData = v.shippingAddressList.filter((item) => {
              return item.cityList && item.cityList.length > 0 && item.districtList.length > 0
            });
            v.shippingAddressList.map((item, index) => {
              cityData.map((talg) => {
                if (item.state === talg.state && item.city === talg.city && item.district === talg.district) {
                  v.$set(v.shippingAddressList[index], 'districtName', talg.districtName);
                  v.$set(v.shippingAddressList[index], 'districtList', talg.districtList);
                }
                if (item.state === talg.state && item.city === talg.city && item.district !== talg.district) {
                  v.$set(v.shippingAddressList[index], 'cityName', talg.cityName);
                  v.$set(v.shippingAddressList[index], 'cityList', talg.cityList);
                }
                if (item.state === talg.state && item.city !== talg.city && item.district !== talg.district) {
                  let cityName = talg.cityList.filter((ele) => {
                    return ele.code === item.city
                  })[0].cnName;
                  v.$set(v.shippingAddressList[index], 'cityName', cityName);
                  v.$set(v.shippingAddressList[index], 'cityList', talg.cityList);
                }
              });
              let obj = item.name + '，' + item.countryCodeName + ' - ' + item.stateName + ' - '
                + item.cityName + ' - ' + item.districtName + ' - ' + item.address;
              v.$set(v.shippingAddressList[index], 'shippingAddressName', obj);
            });
            if (v.shippingAddressList.length < 2) {
              v.logisticsInfoForm.shippingAddress = v.shippingAddressList[0].addressId;
            }
            v.$forceUpdate();
          }, 2000);
        }
      }
    },
    // 获取运输方式的数据
    getExpressageHundredList() {
      let v = this;
      v.axios.get(api.get_expressageHundred_query + '?type=kuaidi100').then(response => {
        if (response.data.code === 0) {
          v.typeShippingList = response.data.datas;
        }
      });
    },
    // 物流信息弹窗--结束装箱、打印箱唛
    endPackingBtn() {
      let v = this;
      let params = {};
      let query = {
        deliveryType: v.logisticsInfoForm.deliveryType,
        expressageCode: v.logisticsInfoForm.expressageCode,
        expressageNo: v.logisticsInfoForm.expressageNo,
        senderAddress: '',
        senderCity: null,
        senderCountryCode: null,
        senderDistrict: null,
        senderName: null,
        senderPhone: null,
        senderState: null,
        transportType: v.logisticsInfoForm.transportType,
        wmsPickupOrderId: v.wmsPickupOrderId,
        ymsLogisServiceId: v.logisticsInfoForm.ymsLogisServiceId
      }
      let methods = '';
      let url = '';
      let validateField = [];
      if (v.logisticsInfoForm.deliveryType === 1) {
        validateField = ['ymsLogisServiceId', 'shippingAddress'];
        if (v.logisticsInfoForm.transportType === 2) {
          validateField = ['shippingAddress'];
        }
        v.shippingAddressList.map((item) => {
          if (item.addressId === v.logisticsInfoForm.shippingAddress) {
            query.senderAddress = item.address;
            query.senderCity = item.cityName;
            query.senderCountryCode = item.countryCode;
            query.senderDistrict = item.district;
            query.senderName = item.name;
            query.senderPhone = item.phone;
            query.senderState = item.stateName;
          }
        });
        query.expressageCode = null;
        query.expressageNo = '';
      } else {
        validateField = ['expressageCode', 'expressageNo'];
      }
      switch (v.optionsType) {
        // 结束装箱
        case 'endPacking':
          url = api.put_wmsPickupOrder_overPickupOrder;
          methods = 'put';
          params = JSON.parse(JSON.stringify(query));
          break;
        case 'shipmentRecord':
          url = api.put_wmsPickupOrder_updateLogistics;
          methods = 'put';
          params = JSON.parse(JSON.stringify(query));
          break;
        case 'boxManagement':
          url = api.put_wmsPickupOrder_updateLogistics;
          methods = 'put';
          params = JSON.parse(JSON.stringify(query));
          break;
        // 打印箱唛
        case 'markShipment':
          url = api.post_wmsPickupOrder_batchDeliver;
          methods = 'post';
          params = {
            pickupOrderBo: JSON.parse(JSON.stringify(query)),
            ...v.pageParamsObj,
          }
          break;
        // 修改物流信息
        case 'billLadingManage':
          url = api.post_ymsPickupOrder_updateLogisticsProviderInfo;
          methods = 'post';
          params = {
            businessId: v.wmsPickupOrderId,
            expressageCode: v.logisticsInfoForm.expressageCode,
            expressageNo: v.logisticsInfoForm.expressageNo
          }
          break;
      }
      Promise.all(validateField.map(field => {
        return new Promise(function (resolve) {
          v.$refs['logisticsInfoForm'].validateField(field, (error) => {
            resolve(error)
          })
        })
      })).then((data) => {
        if (data.every(i => !i)) {
          v.axios[methods](url, params, {loading: true, loadingText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000212')}).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas;
              if (data) {
                v.logisticsInfoModal = false;
                switch (v.optionsType) {
                  case 'endPacking':
                    v.$Message.success(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000323'));
                    let obj = {
                      value: 'packingManage',
                      type: 'scanning'
                    };
                    v.$emit('changeTabs', obj);
                    break;
                  case 'markShipment':
                    let code = data.pickupOrderNumber;
                    let content = `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001146')}${code}`;
                    this.$Message.success({
                      content: content,
                      duration: 10,
                      closable: true
                    });
                    v.$emit('updateData', data);
                    break;
                  case 'shipmentRecord':
                  case 'boxManagement':
                  case 'billLadingManage':
                    v.$Message.success(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000323'));
                    v.$emit('updateData', data);
                    break;
                }
              }
            }
          });
        } else {
          console.log('===校验参数失败!===');
        }
      });
    },
    // 监听物流信息的弹窗
    logisticsInfoModalChange(value) {
      if (!value) {
        this.$refs['logisticsInfoForm'].resetFields();
        this.typeShippingList = [];
        this.logisticsPlanList = [];
        this.shippingAddressList = [];
        this.pageParamsObj = null;
        this.wmsPickupOrderId = null;
      }
    },
    // 切换物流方式
    deliveryTypeChange(value) {
      this.logisticsInfoForm.ymsLogisServiceId = null;
      this.logisticsInfoForm.expressageCode = null;
      this.logisticsInfoForm.expressageNo = '';
      if (value === 1) {
        this.logisticsInfoForm.transportType = 1;
        this.logisticsInfoForm.shippingAddress = null;
      } else {
        this.logisticsInfoForm.transportType = 1;
      }
    },
    // 切换运输方式
    transportTypeChange(value) {
      if (value === 2) {
        this.logisticsInfoForm.ymsLogisServiceId = null;
      }
    }
  }
}
</script>

<style scoped>

</style>