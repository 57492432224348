<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1001084')" prop="status">
              <Select v-model="pageParams.status" transfer filterable>
                <Option v-for="item in statusList" :key="item.value" :value="item.value">{{ item.title }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1000870')">
              <Date-picker
                style="width:100%"
                type="datetimerange"
                :transfer="true"
                @on-clear="resetDate"
                @on-change="getDateValue"
                :clearable="true"
                :options="dateOptions"
                format="yyyy-MM-dd HH:mm:ss"
                placement="bottom-end"
                :placeholder="$t('key1000268')"
                :value="timeValue">
              </Date-picker>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <dropDownFilter
              ref="dropDownFilter"
              inputType="textarea"
              :selectStyle="{width: '110px'}"
              :dropDownInputList="dropDownInputList"
              :defaultSelectValue="defaultSelectValue"
              @changeInput="changeInput"
              @updateInputValue="updateInputValue">
            </dropDownFilter>
          </Col>
          <Col style="width: 200px;">
            <Button @click="reset" v-once icon="md-refresh" class="mr10">{{ $t('key1000094') }}</Button>
            <Button type="primary" @click="search" icon="ios-search">{{ $t('key1000269') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box" v-if="getPermission('wmsPickupOrder_exportWmsPickupOrderExcel')">
      <Dropdown @on-click="changeExportBtn">
        <Button type="primary">
          <span>{{ $t('key1002291') }}</span>
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem name="1">{{ $t('key1002292') }}</DropdownItem>
          <DropdownItem name="2">{{ $t('key1002293') }}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table
        ref="selection"
        highlight-row
        border
        :loading="tableLoading"
        max-height="520"
        :columns="tableColumns"
        :data="tableData"
        @on-selection-change="tableChange"
        @on-sort-change="sortChange">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        :current="pageParams.pageNum"
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
    <!--导出数据字段弹窗-->
    <exportFieldsModal
      ref="exportFields"
      :exportFieldsTitle="$t('key1002291')"
      paramKeyColums="wmsPickupOrderExport"
      :customFieldExportList="customFieldExportList"
      @exportFieldsDataBtn="exportFieldsDataBtn">
    </exportFieldsModal>
    <!--打印箱唛-->
    <printCaseMarkModal
      ref="printTalg"
      :apiUrl="apiUrl"
      service="yms-supplier-service"
      typeTalg="packingManage">
    </printCaseMarkModal>
    <!--修改物流信息弹窗-->
    <logisticsInfoModal ref="logisticsInfo" @updateData="pageParamsStatus = true"></logisticsInfoModal>
  </div>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import exportFieldsModal from "@/components/common/exportFieldsModal.vue";
import printCaseMarkModal from "@/components/common/printCaseMarkModal.vue";
import logisticsInfoModal from "@/components/common/logisticsInfoModal.vue";
import {jumpToTheCourierInquiry, pageJump, setConfigCharacters} from "@/utils/common";
import dropDownFilter from "@/components/common/dropDownFilter.vue";

export default {
  name: "shipmentRecord",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      pageParamsStatus: false,
      pageParams: {
        status: 'null', // 0:正在装箱，1:等待揽收，2:已揽收，3.YMS已收货
        wmsPickupOrderNumbers: null,
        thirdPickupOrderNumbers: null,
        createdStartTime: null,
        createdEndTime: null,
        upDown: 'down',
        orderBy: 'createdTime',
        pageNum: 1,
        pageSize: 15
      },
      selectTableData: [],
      tableData: [],
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          fixed: 'left',
          align: 'center'
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002816'),
          key: 'pickupOrderNumber',
          align: 'center',
          minWidth: 160
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003163'),
          key: 'thirdPickupOrderNumber',
          align: 'center',
          minWidth: 160,
          render(h, params) {
            let thirdPickupOrderNumber = params.row.thirdPickupOrderNumber || '-';
            return h('span', thirdPickupOrderNumber);
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000506'),
          key: 'status',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            let statusTxt = '-';
            let status = params.row.status;
            let content = params.row.invalidReason;
            v.statusList.map((item) => {
              if (item.value === status) {
                statusTxt = item.title;
              }
            });
            return h('div', {class: 'flex align-items-center'}, [
              h('span', statusTxt),
              status === 99 ? h('Tooltip', {
                props: {
                  transfer: true,
                  content: content,
                  maxWidth: 300,
                  placement: 'top'
                }
              }, [
                h('Icon', {
                  class: 'ml3 cursor',
                  props: {
                    type: 'md-help-circle',
                    size: 17,
                    color: '#7F7F7F'
                  },
                })
              ]) : ''
            ]);
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002990'),
          key: 'deliveryMethod',
          align: 'center',
          minWidth: 170,
          render(h, params) {
            let deliveryType = params.row.deliveryType; // 发货方式{1YMS物流 2自有物流}
            let transportType = params.row.transportType; // 运输方式{1快递 2上门揽收}
            let text = '';
            if (deliveryType === 1) {
              text = `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002961')}` + ' ' + '-' + ' ' + (transportType === 1 ? alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001138') : alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002975'));
            } else {
              text = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001137')
            }
            return h('span', text);
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001151'),
          key: 'logisticsInfo',
          align: 'center',
          minWidth: 170,
          render(h, params) {
            let deliveryType = params.row.deliveryType; // 发货方式{1YMS物流 2自有物流}
            let transportType = params.row.transportType; // 运输方式{1快递 2上门揽收}
            let expressageNo = params.row.expressageNo;
            let expressageCode = params.row.expressageCode;
            let trackingNumber = params.row.trackingNumber;
            return h('div', [
              deliveryType === 1 && transportType === 2 ? h('span', `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002975')}`) :
                h('div', [
                  h('div', {class: 'mt10'}, ''),
                  h('Alink', {
                    props: {
                      linkName: expressageNo,
                      Aicon: '&#xe6e7;',
                      AlinkShow: false,
                      AiconSize: '22px',
                      AiconShow: expressageCode ? true : false,
                      AiconColor: '#2D8CF0',
                      styles: {
                        justifyContent: 'center'
                      }
                    },
                    on: {
                      AIconBtn: () => {
                        jumpToTheCourierInquiry(expressageCode, expressageNo, trackingNumber)
                      }
                    }
                  })
                ])
            ])
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003898'),
          key: 'totalQuantities',
          minWidth: 130,
          align: 'center'
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005485'),
          key: 'totalActualSortingNumber',
          minWidth: 150,
          align: 'center',
          render(h, params) {
            let totalQuantities = params.row.totalQuantities;
            let totalActualSortingNumber = params.row.totalActualSortingNumber;
            let talg = false;
            if (totalQuantities && totalActualSortingNumber && totalActualSortingNumber !== totalQuantities) {
              talg = true;
            }
            return h('span', {class: talg ? 'redColor' : ''}, totalActualSortingNumber)
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005486'),
          key: 'totalSupplierOverSortingNumber',
          minWidth: 140,
          align: 'center',
          render(h, params) {
            let totalSupplierOverSortingNumber = params.row.totalSupplierOverSortingNumber || '-';
            return h('span', {class: totalSupplierOverSortingNumber !== '-' ? 'redColor' : ''}, totalSupplierOverSortingNumber);
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003203'),
          key: 'abnormalStatus',
          align: 'center',
          minWidth: 160,
          render(h, params) {
            let text = '-';
            let talg = false;
            let hasStockPackage = params.row.hasStockPackage; // 是否有多发补单 0 无 1 有
            let wmsPickupOrderId = params.row.wmsPickupOrderId;
            let status = params.row.status;
            let pickupOrderNumber = params.row.pickupOrderNumber;
            let ymsSortQuantities = params.row.ymsSortQuantities || 0;
            let totalQuantities = params.row.totalQuantities;
            if ((totalQuantities > 0 && totalQuantities !== ymsSortQuantities)
              || hasStockPackage === 1 || totalQuantities !== ymsSortQuantities) {
              text = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003204');
              talg = true;
            }
            return status === 3 ? h('Alink', {
              props: {
                linkName: text,
                AlinkShow: talg,
                defineColor: talg ? '#FF3300' : '',
                styles: {
                  justifyContent: 'center'
                }
              },
              on: {
                AlinkBtn: () => {
                  v.$router.push({
                    path: '/shipmentRecordDetails',
                    query: {
                      pickupOrderNumber: pickupOrderNumber,
                      wmsPickupOrderId: wmsPickupOrderId
                    }
                  })
                }
              }
            }) : h('span', '-');
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002807'),
          key: 'logisticsCostCurrency',
          align: 'center',
          minWidth: 130,
          render(h, params) {
            let logisticsCostCurrency = params.row.logisticsCostCurrency;
            let logisticsCost = params.row.logisticsCost || '-';
            let deliveryType = params.row.deliveryType; // 发货方式{1YMS物流 2自有物流}
            let transportType = params.row.transportType; // 运输方式{1快递 2上门揽收}
            let text = '-';
            if (deliveryType === 1 && transportType === 1) {
              if (logisticsCost && logisticsCostCurrency) {
                text = logisticsCost + ' ' + logisticsCostCurrency;
              }
            }
            return h('span', text);
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001255'),
          key: 'subsidyAmount',
          align: 'center',
          minWidth: 130,
          render(h, params) {
            let text = '-';
            let ruleName = params.row.ruleName;
            let auditStatus = params.row.auditStatus; // 1为待审核
            let subsidyAmount = params.row.subsidyAmount;
            let content = '';
            if (auditStatus === 1) {
              content = `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005480')}`;
              text = '-';
            } else {
              if (subsidyAmount) {
                text = subsidyAmount + v.currencyState;
                if (ruleName) {
                  let list = ruleName.split(',');
                  if (list && list.length > 0) {
                    list.map((item) => {
                      item = `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005481')}${item}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002963')}`;
                    });
                    content = list.join('、');
                  }
                }
              } else {
                content = `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005482')}${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005483')}`;
              }
            }
            return h('div', {class: 'flex justify-content-center align-items-center'}, [
              h('span', text),
              h('Tooltip', {
                  props: {
                    transfer: true,
                    maxWidth: 250,
                    placement: 'top',
                    content: content
                  },
                  style: {
                    marginLeft: '5px'
                  }
                },
                [
                  h('Icon', {
                    props: {
                      type: 'md-help-circle',
                      size: 17
                    },
                    style: {
                      cursor: 'pointer',
                      color: '#7F7F7F'
                    }
                  })
                ])
            ])
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003199'),
          key: 'trackingMessage',
          align: 'center',
          minWidth: 150,
          render(h, params) {
            let deliveryType = params.row.deliveryType; // 发货方式{1YMS物流 2自有物流}
            let transportType = params.row.transportType; // 运输方式{1快递 2上门揽收}
            let trackingMessage = '-';
            if (deliveryType === 1 && transportType === 1) {
              trackingMessage = params.row.trackingMessage || '-'
            }
            return h('span', trackingMessage)
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005487'),
          key: 'warehouseId',
          align: 'center',
          minWidth: 140,
          render(h, params) {
            return h('span', v.getWarehouseName(params.row.warehouseId));
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001577'),
          key: 'createdTime',
          sortable: 'custom',
          sortType: 'desc',
          minWidth: 170,
          align: 'center',
          render: (h, params) => {
            return h('div', v.$uDate.getDataToLocalTime(params.row.createdTime, 'fulltime') || '-');
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002818'),
          key: 'pickupTime',
          sortable: 'custom',
          align: 'center',
          minWidth: 170,
          render: (h, params) => {
            return h('div', v.$uDate.getDataToLocalTime(params.row.pickupTime, 'fulltime') || '-');
          }
        },
        {
          title: ` ${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002967')}`,
          key: 'ymsPickingDate',
          sortable: 'custom',
          align: 'center',
          minWidth: 170,
          render: (h, params) => {
            return h('div', v.$uDate.getDataToLocalTime(params.row.ymsPickingDate, 'fulltime') || '-');
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000278'),
          key: 'action',
          align: 'center',
          width: 200,
          fixed: 'right',
          render: (h, params) => {
            let status = params.row.status;
            let pickupOrderNumber = params.row.pickupOrderNumber;
            let warehouseId = params.row.warehouseId;
            let wmsPickupOrderId = params.row.wmsPickupOrderId;
            let deliveryType = params.row.deliveryType; // 发货方式{1YMS物流 2自有物流}
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000728'),
                    show: v.getPermission('wmsPickupOrder_wmsPickupOrderDetail'),
                    clickFn: () => {
                      v.$router.push({
                        path: '/shipmentRecordDetails',
                        query: {
                          pickupOrderNumber: pickupOrderNumber,
                          wmsPickupOrderId: wmsPickupOrderId
                        }
                      })
                    }
                  },
                  {
                    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000869'),
                    show: v.getPermission('wmsPickupOrder_printBoxShippingMark'),
                    clickFn: () => {
                      v.$refs['printTalg'].getPrintData(wmsPickupOrderId)
                    }
                  },
                  {
                    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005488'),
                    show: status === 1 && v.isWarehouseManagement !== 'Y' && v.getPermission('wmsPickupOrder_appendProductGoods'), // 等待揽收
                    clickFn: () => {
                      v.getSupplierWarehouseSetting(true, 'operationManage').then((val) => {
                        if (val) {
                          v.$router.push({
                            path: '/additionalGoods',
                            query: {
                              pickupOrderNumber: pickupOrderNumber,
                              warehouseId: warehouseId,
                              wmsPickupOrderId: wmsPickupOrderId,
                            }
                          })
                        }
                      })
                    }
                  },
                  {
                    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001153'), // 物流方式为自有物流且等待揽收状态
                    show: status === 1 && deliveryType === 2,
                    clickFn: () => {
                      v.$refs['logisticsInfo'].initLogisticsInfo(wmsPickupOrderId, 'shipmentRecord');
                    }
                  },
                  {
                    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005489'),
                    show: [0, 1].includes(status),
                    clickFn: () => {
                      v.$Modal.confirm({
                        title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005490'),
                        content: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005491'),
                        okText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005489'),
                        cancelText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000097'),
                        onOk: () => {
                          v.cancelBillBtn(wmsPickupOrderId);
                        },
                        onCancel: () => {
                        }
                      });
                    }
                  },
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      statusList: [
        {value: 'null', title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
        {value: 0, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005492')},
        {value: 1, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001228')},
        {value: 2, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001202')},
        {value: 3, title: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003595')}`},
        {value: 99, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001231')},
      ],
      total: 0,
      timeValue: [],
      exportType: '1',
      customFieldExportList: [
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000604'),
          titleKey: 'basicInfoColums',
          selectList: [], // 选中导出字段的key
          checkBoxList: [
            {paramKey: 'pickupOrderNumber', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001317')},
            {paramKey: 'thirdPickupOrderNumber', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005493')},
            {paramKey: 'status', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000506')},
            {paramKey: 'deliveryType', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002990')},
            {paramKey: 'carrierId', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000196')},
            {paramKey: 'carrierCode', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005494')},
            {paramKey: 'logisticsCost', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002807')},
            {paramKey: 'subsidyAmount', paramValue: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001255')}`},
            {paramKey: 'ymsWarehouse', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001716')},
            {paramKey: 'totalQuantities', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003898')},
            {paramKey: 'totalActualSortingNumber', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005485')},
            {paramKey: 'totalSupplierOverSortingNumber', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005486')},
            {paramKey: 'errorStatus', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003203')},
            {paramKey: 'createdTime', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001577')},
            {paramKey: 'pickupTime', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002818')},
            {paramKey: 'ymsPickingDate', paramValue: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005484')}`},
            {paramKey: 'createdBy', paramValue: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001576')},
          ]
        }
      ],
      apiUrl: api.put_wmsPickupOrder_printBoxShippingMark,
      dropDownInputList: [
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002816'), value: 1, placeholder: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005495'), parameterKey: 'wmsPickupOrderNumbers'},
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003163'), value: 2, placeholder: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003209'), parameterKey: 'thirdPickupOrderNumbers'}
      ],
      defaultSelectValue: 1,
    }
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
    // 是否开启仓储管理配置
    isWarehouseManagement() {
      return this.$store.state.isWarehouseManagement;
    },
    filterKeyList() {
      let parameterKeyList = this.dropDownInputList.map((item) => {
        return item.parameterKey
      });
      return parameterKeyList;
    }
  },
  created() {
    this.setDefaultTimerParams('three_months');
    this.initFun();
  },
  methods: {
    // 初始化接口数据
    initFun() {
      (async () => {
        await this.getBelongingWarehouseData(this.userInfo.merchantId).then((data) => {
          if (data && data.length > 0) {
            this.warehouseList = data;
          }
        });
        await this.getList();
      })();
    },
    // 获取创建时间
    getDateValue(value) {
      let v = this;
      if (value.length === 0) {
        v.$Message.error(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000284'));
        v.resetDate();
      } else {
        if (value[0] !== '') {
          let timeList = v.defaultTimePeriod(value);
          v.timeValue = v.defaultTimePeriod(value, false);
          v.pageParams.createdStartTime = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
          v.pageParams.createdEndTime = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
        }
      }
    },
    // 重置创建时间
    resetDate() {
      let v = this;
      v.pageParams.createdStartTime = null;
      v.pageParams.createdEndTime = null;
    },
    // 重置数据
    reset() {
      this.resetDate();
      this.timeValue = [];
      this.$refs['pageParams'].resetFields();
      this.setDefaultTimerParams('three_months');
      this.changeInput(1);
      this.search();
    },
    // 查询按钮
    search() {
      let v = this;
      v.pageParams.pageNum = 1;
      v.pageParamsStatus = true;
    },
    // 获取列表的数据
    getList() {
      let v = this;
      v.optionBtnWidth = [];
      v.tableData = [];
      v.total = 0;
      if (v.getPermission('wmsPickupOrder_queryWmsPickupOrderList')) {
        v.tableLoading = true;
        v.axios.post(api.post_wmsPickupOrder_queryWmsPickupOrderList, v.handleParameter()).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data) {
              v.tableData = data.list || [];
              v.total = data.total;
              v.updateTableDom('selection');
            }
          } else {
            v.tableLoading = false;
          }
        });
      }
    },
    // 参数处理
    handleParameter() {
      let v = this;
      let params = Object.assign({}, v.pageParams);
      params.status = v.pageParams.status === 'null' ? null : v.pageParams.status;
      for (let key in params) {
        if (v.filterKeyList.includes(key)) {
          params[key] = v.sepCommasFn(params[key], [',', '，', '\n']);
        }
      }
      return params;
    },
    // 勾选列表数据
    tableChange(data) {
      this.selectTableData = [];
      data.map((item) => {
        if (item.pickupOrderNumber) {
          this.selectTableData.push(item.pickupOrderNumber);
        }
      });
    },
    // 选择导出数据类型  1 按选中导出 2 按查询结果导出
    changeExportBtn(value) {
      this.exportType = value;
      if (value === '1') {
        if (this.selectTableData.length <= 0) {
          this.$Message.warning(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002294'));
          return false;
        }
      } else {
        if (this.tableData.length <= 0) {
          this.$Message.warning(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002295'));
          return false;
        }
      }
      this.$refs['exportFields'].getExportFieldsSetting();
    },
    // 导出数据
    exportFieldsDataBtn() {
      let v = this;
      let query = v.handleParameter();
      if (v.exportType === '1') {
        query.wmsPickupOrderNumbers = v.selectTableData;
      } else {
        query.wmsPickupOrderNumbers = [];
      }
      v.$refs['exportFields'].saveExportFieldsSetting().then((value) => {
        if (value) {
          v.axios.post(api.post_wmsPickupOrder_exportWmsPickupOrderExcel, query,
            {loading: true, loadingText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000212')}).then(res => {
            if (res.data.code === 0) {
              v.$refs['exportFields'].exportFieldsModal = false;
              let taskNumber = res.data.datas;
              setTimeout(() => {
                pageJump('/supplier.html#/exportTask?operateCode=' + taskNumber, '/yms-supplier-service', '_blank');
              }, 30)
            }
          });
        }
      });
    },
    // 创建时间排序
    sortChange(data) {
      let obj = {
        desc: 'down',
        asc: 'up'
      }
      this.pageParams.upDown = obj[data.order];
      this.pageParams.orderBy = data.key;
      this.pageParamsStatus = true;
    },
    // 作废提单
    cancelBillBtn(wmsPickupOrderId) {
      let v = this;
      v.axios.post(api.post_wmsPickupOrder_invalid + wmsPickupOrderId, null, {
        loading: true,
        loadingText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000212')
      }).then(res => {
        if (res.data.code === 0) {
          v.$Message.success(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005496'));
          v.pageParamsStatus = true;
        }
      });
    },
    // 获取下拉选项筛选条件的值
    updateInputValue(data) {
      this.pageParams[data.key] = data.value;
    },
    // 改变默认下拉选项的值
    changeInput(value) {
      this.defaultSelectValue = value;
      this.$refs.dropDownFilter.inputValue = '';
      this.dropDownInputList.map((item) => {
        if (Object.keys(this.pageParams).includes(item.parameterKey)) {
          this.pageParams[item.parameterKey] = '';
        }
      });
    },
  },
  watch: {
    pageParamsStatus(n) {
      let v = this;
      if (n) {
        v.getList();
        v.pageParamsStatus = false;
      }
    }
  },
  components: {
    dropDownFilter,
    logisticsInfoModal,
    exportFieldsModal,
    printCaseMarkModal
  }
}
</script>

<style scoped>

</style>